#root {
  align-items: stretch;
  background-color: var(--color-page-background);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  & > main {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
  }

  & > .main-header,
  & > .main-footer {
    flex-grow: 0;
  }

  * {
    box-sizing: border-box;
  }
}

svg {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  height: 1em;
  overflow: hidden;
  width: 1em;

  & path:only-child {
    fill: currentColor;
  }
}
/*
HELPER: MAKE ANYTHING ORDERED
*/

body {
  counter-reset: order-lv-1;
  counter-reset: order-lv-2;

  .ordered {
    counter-increment: order-lv-1;
    counter-reset: order-lv-2;

    &::before {
      content: counter(order-lv-1) '. ';
    }
  }

  .ordered-level-2 {
    counter-increment: order-lv-2;

    &::before {
      content: counter(order-lv-1) '.' counter(order-lv-2) '. ';
    }
  }
}
